<template>
  <div class="create-process">
    <div class="work-instruction">
      <h2 class="block-title">
        작업지시서
      <v-dialog 
        content-class="create-new-process-dialog"
        v-model="work_instruct.dialog.open"
        @click:outside="closeCreateProcess"
        dark
      >
        <template v-slot:activator="{ attr, on }">
          <v-btn 
            class="create-process rounded-lg" 
            :class="{ 'mac': is_mac, }"
            @click="openProcessForm"
            v-bind="attr"
            v-on="on"
            outlined
          >신규 작업 생성</v-btn>
        </template>
        <v-card class="inner">
          <v-form ref="form" v-model='valid' lazy-validation>  
            <p class="dialog-title">
              <template v-if="work_instruct.dialog.mode =='create'">신규 작업 생성</template>
              <template v-else-if="work_instruct.dialog.mode='modify'">공정 수정</template>
            </p>
            <div 
              class="dialog__content"
              :class="{ 'mac': is_mac }"
            >
              <label class="dialog__form-label">
                <span class="label-text">고 객 사</span>
                <select name="client-list " 
                  v-model='work_instruct.dialog.buyer_id'  
                  class="client-list dialog__form-select"
                >
                  <option value="" disabled>고객사</option>
                  <option 
                    v-for="client, i in client"
                    :key="client.name + '-' + i"
                    :value='client.id'
                    class="client"
                  >{{ client.name }}</option>
                </select>
              </label>
              <label class="dialog__form-label">
                <span class="label-text">공 정 명</span>
                <v-text-field 
                  class="label-field rounded-0"
                  height="38"
                  v-model="work_instruct.dialog.product_name"
                  :rules="nameRule"
                  hide-details solo dense flat light
                  label="공정명"
                  required
                ></v-text-field>
              </label>
              <label class="dialog__form-label">
                <span class="label-text">수 량</span>
                <v-text-field 
                  class="label-field rounded-0"
                  v-model="work_instruct.dialog.quantity"
                  height="38"
                  :type="'number'"
                  :rules="countRule"
                  hide-details solo dense flat light
                  required
                  label="수량"
                ></v-text-field>
              </label>
              <label class="dialog__form-label">
                <span class="label-text">완 료 목 표 일</span>
                <dayPicker
                  class="label-field"
                  :preset="work_instruct.dialog.due_date"
                  :key="'process-date'"
                  :fieldHeight="38"
                  :clickPick="true"
                  @picked='setDate'
                  :offsetX="true"
                  :offsetY="false"
                  :right="true"
                ></dayPicker>
              </label>
            </div>
            <v-card-actions class="dialog__actions" :class="{ 'mac': is_mac }">
              <v-btn 
                class="action-btn action-cancle" 
                @click="closeCreateProcess"
                depressed light
              >취소</v-btn>
              <v-btn 
                class="action-btn action-add" 
                v-if="work_instruct.dialog.mode == 'create'"
                :disabled="!valid"
                @click="createProcess"
                depressed light
              >추가</v-btn>
              <v-btn 
                class="action-btn action-add" 
                v-else-if="work_instruct.dialog.mode == 'modify'"
                @click="modifyProcess"
                :disabled="!valid"
                depressed light
              >수정</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      </h2>
      <div id="table-height-standard" class="work-instruction-table">
        <v-data-table
          class="skyblue-data-table"
          :class="{ 'mac': is_mac }"
          :headers="work_instruct.header"
          :items="work_instruct.data"
          :items-per-page="-1"
          :height="table_height"
          :sort-by.sync="work_instruct.sort.sort_by"
          :sort-desc.sync="work_instruct.sort.sort_desc"
          hide-default-footer hide-default-header
          fixed-header show-select
        >
          <template v-slot:header="{props}">
            <thead class="v-data-table-header custom-header" :class="{ 'mac': is_mac, }">
              <tr>
                <th
                  v-for="head in props.headers"
                  :key="head.name"
                  class="header"
                  :style="{ 
                    'width': head.width, 
                    'text-align': head.align, 
                  }"
                >
                  <span class="header-text" >{{ head.text }}</span>
                  <v-btn 
                    v-if="head.sortable"
                    class="header__sort-btn rounded-0" 
                    :class="{ 
                      'sort--active': work_instruct.sort.sort_by == head.value,
                      'sort--desc': work_instruct.sort.sort_desc,
                    }"
                    :ripple="false"
                    @click="tableSort(head.value)"
                    fab x-small text plain
                  >
                    <v-icon size="12">$sort</v-icon>
                  </v-btn>
                </th>
              </tr>
            </thead>
          </template>

          <template v-slot:item="props">
            <tr class="process_row" 
              :class="{ 'row--selected': work_instruct.selected == props.item.id, }"
              @click="clickRow(props.item)"
            >
              <td class="row-select">
                <p 
                  class="selected-mark"
                ></p>
              </td>
              <td>{{ props.item.name }}</td>
              <td class="product-name-cell">
                {{ props.item.product_name }}
              </td>
              <td>{{ props.item.quantity }}</td>
              <td>{{ props.item.due_date }}</td>

              <div class="work-instruction__actions">
                <v-btn 
                  class="action-btn line_modify"
                  fab depressed x-small outlined
                  @click="modifyWork(props.item)"
                >
                <v-icon size="16">$modify</v-icon>
                </v-btn>
                <v-btn 
                  class="action-btn line_delete"
                  fab depressed x-small outlined
                  @click="openDeleteConfirm(props.item.product_name, props.item.id)"
                >
                  <v-icon size="16">$deleteIcon</v-icon>
                </v-btn>

              </div>
            </tr>
          </template>
        </v-data-table>
      </div>
    </div>
    <div class="machine-status"
      :class="{
        'machine-status--expand': machine_status.expand,
      }"
    >
      <v-btn 
        class="expand-machine-status"
        @click="machine_status.expand = !machine_status.expand"
        tile depressed
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <h2 class="block-title">
        가공기계 현황<span class="program-name sub-title"> {{ machine_status.program }}</span>
      </h2>
      <div class="machine-status-table">
        <v-data-table
          class="skyblue-data-table"
          :class="{ 'mac': is_mac }"
          :headers="machine_status.header"
          :items="machine_status.data"
          :items-per-page="-1"
          :height="table_height"
          :loading ="loading"
          :sort-by.sync="work_instruct.sort.sort_by"
          :sort-desc.sync="work_instruct.sort.sort_desc"
          hide-default-footer hide-default-header
          fixed-header
        >

          <template v-slot:header="{props}">
            <thead class="v-data-table-header custom-header" :class="{ 'mac': is_mac, }">
              <tr>
                <th
                  v-for="head in props.headers"
                  :key="head.name"
                  class="header"
                  :style="{ 'width': head.width, }"
                >
                  <span class="header-text">
                    {{ head.text }}
                    <span class="unit" v-if="head.unit != undefined">({{ head.unit }})</span>
                  </span>
                  <v-btn 
                    v-if="head.sortable"
                    class="header__sort-btn rounded-0" 
                    :class="{ 
                      'sort--active': machine_status.sort.sort_by == head.value,
                      'sort--desc': machine_status.sort.sort_desc,
                    }"
                    :ripple="false"
                    @click="tableSort(head.value)"
                    fab x-small text plain
                  >
                    <v-icon size="12">$sort</v-icon>
                  </v-btn>
                </th>
              </tr>
            </thead>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn 
              class="add-process-schedule rounded-lg"
              :class="{ 'mac': is_mac, }"
              @click="openScheduleForm(item.mkey)"
              outlined
            >공정 스케줄 등록</v-btn>
          </template>
        </v-data-table>
      </div>
    </div>

    <v-dialog
      content-class="process-schedule-dialog"
      v-model="machine_status.dialog.open"
      @click:outside="closeAddSchedule"
      dark
    >
      <v-card class="inner">
        <v-form ref="form1" v-model='valid_status' lazy-validation>  
          <p class="dialog-title">공정 스케줄 등록</p>
          <div 
            class="dialog__content"
            :class="{ 'mac': is_mac }"
          >
            <label class="dialog__form-label">
              <span class="label-text">세 팅 시 간</span>
              <v-text-field 
                class="label-field rounded-0"
                v-model="machine_status.dialog.setting_time"
                height="38"
                suffix="분"
                type="number"
                required hide-details dense solo flat light
                :rules="settingRule"
              ></v-text-field>
            </label>
            <label class="dialog__form-label">
              <span class="label-text">가 공 시 간</span>
              <v-text-field 
                v-model="machine_status.dialog.active_time"
                class="label-field rounded-0"
                height="38"
                suffix="초"
                type="number"
                :rules="timeRule"
                required hide-details dense solo flat light
              ></v-text-field>
            </label>
            <label class="dialog__form-label">
              <span class="label-text">수 량</span>
              <v-text-field 
                v-model="machine_status.dialog.quantity"
                class="label-field rounded-0"
                height="38"
                suffix="개"
                type="number"
                required hide-details dense solo flat light
                :rules="numberRule"
              ></v-text-field>
            </label>
            <label class="dialog__form-label">
              <span class="label-text">가 공 예 정 일</span>
              <dayTimePicker 
                class="label-field"
                :preset="{ date: machine_status.dialog.date, time: machine_status.dialog.time }"
                :right="true"
                :offsetY="true"
                :presetIcon="['prepend', false, true,]"
                :fieldHeight="38"
                @picked='setDayTime'
              ></dayTimePicker>
            </label>
          </div>
          <v-card-actions class="dialog__actions">
              <v-btn 
                class="action-btn action-cancle" 
                @click="closeAddSchedule"
                :class="{ 'mac': is_mac }"
                depressed light
              >취소</v-btn>
              <v-btn 
                class="action-btn action-add" 
                @click="addSchedule"
                :class="{ 'mac': is_mac }"
                :disabled="!valid_status"
                depressed light
              >등록</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog 
      content-class="process-delete-confirm"
      v-model="work_instruct.confirm.open"
      dark
    >
      <v-card class="inner">
        <div class="dialog__content">
          <div class="dialog__confirm-text">
            <strong>{{ work_instruct.confirm.program }}</strong>(을)를<br>
            정말 삭제하시겠습니까?
          </div>
        </div>
        <v-card-actions class="dialog__actions" :class="{ 'mac': is_mac }">
          <v-btn 
            class="action-btn confirm-cancle" 
            @click="work_instruct.confirm.open = false"
            light
          >취소</v-btn>
          <v-btn 
            class="action-btn delete-process" 
            @click="deleteProcess" 
            light
          >확인</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import dayPicker from '../components/dayPicker.vue'
import dayTimePicker from '../components/dayTimePicker.vue'

export default {
  components: {
    dayPicker, dayTimePicker,
  },
  data() {
    return {
      nameRule: [
        v => !!v || '공정명을 입력하세요.'
      ],
      countRule: [
        v => !!v || '담당자를 입력하세요.'
      ],
      numberRule: [
        v => !!v || '수량을 입력하세요.'
      ],
      timeRule: [
        v => !!v || '가공시간을 입력하세요.'
      ],
      settingRule: [
        v => !!v || '세팅시간을 입력하세요.'
      ],
      today: moment().format('YYYY-MM-DD'),
      table_height: 500,
      client: [
        { id:1, name: 'client1', },
      ],
      work_instruct: {
        header: [
          {
            text: '고객사',
            align: 'left',
            sortable: true,
            value: 'name',
            width: '28%',
          },
          {
            text: '공정명',
            align: 'left',
            sortable: true,
            value: 'product_name',
          },
          {
            text: '수량',
            align: 'left',
            sortable: true,
            value: 'quantity',
            width: '120px',
          },
          {
            text: '완료 목표일',
            align: 'left',
            sortable: true,
            value: 'due_date',
            width: '150px',
          },
        ],
        data: [
        ],
        dialog: {
          mode:'create',
          open: false,
          buyer_id: '',
          product_name: undefined,
          quantity: undefined,
          due_date: moment().format('YYYY-MM-DD'),
        },
        confirm: {
          open: false,
          program: '',
        },
        sort: {
          sort_by: '',
          sort_desc: false,
        },
        selected: '',
      },
      machine_status: {
        program: '',
        header: [
          {
            text: '기계명',
            align: 'left',
            sortable: true,
            value: 'mid',
            width: '24%',
          },
          {
            text: '현 공정 완료 예정',
            align: 'left',
            sortable: true,
            value: 'lot_endtime',
          },
          {
            text: '수량',
            align: 'left',
            sortable: true,
            value: 'count',
            unit: 'EA',
            width: '13%',
          },
          {
            text: '교체 시간',
            align: 'left',
            sortable: true,
            value: 'idle',
            unit: 'sec',
            width: '13%',
          },
          {
            text: '',
            align: 'left',
            sortable: false,
            value: 'actions',
            width: '200px',
          },
        ],
        data: [
          {
            mid: 'Puma280',
            lot_endtime: '2022-01-04',
            count: 300,
            idle: 20,
          },
        ],
        dialog: {
          open: false,
          setting_time: undefined,
          active_time: undefined,
          quantity: undefined,
          date: moment().format('YYYY-MM-DD'),
          time: '12:00',
        },
        expand: false,
        sort: {
          sort_by: '',
          sort_desc: false,
        },
      },
      select_process:{},
      loading:false,
      valid:true,
      valid_status:true,
    };
  },
  computed: {
    is_mac() {
      return this.$is_mac;
    },
  },
  methods: {
    getWork(){
      this.$http.APS.get('/order/').then(r=>{
        for(let i = 0; i < r.data.length; i++){
          r.data[i].due_date= this.dateFormatter(r.data[i].due_date);
        }
        if(r.data.length != 0){
          this.work_instruct.selected = r.data[0].id;
          this.machine_status.program = r.data[0].product_name;
          this.select_process = r.data[0];
          this.work_instruct.data = r.data;
        }
      })
    },
    getSelect(){
      this.$http.APS.get('/order/box').then(r=>{
        this.client=r.data;
      })
    },
    dateFormatter(v){
      v = v.substr(0,10);
      return v;
    },
    async modifyProcess(){
      const work = this.work_instruct;
      await this.$http.APS.patch('/order/setm',work.dialog);
      await this.getWork();
      await this.resetDialog();
    },
    clickRow(v){
      this.work_instruct.selected = v.id;
      this.select_process = v;
      this.machine_status.program = v.product_name;
      // this.loading = true;
    },
    modifyWork(v){
      const work = this.work_instruct;
      work.dialog= v;
      work.dialog.mode='modify';
      work.dialog.open = true;
    },
    async deleteProcess(){
      const inst = this.work_instruct;
      const id = inst.confirm.id;

      inst.confirm.open = false;
      await this.$http.APS.delete('/order/delm/' + id);
      await this.getWork();
    },
    openDeleteConfirm(name, id) {
      const inst = this.work_instruct;
      inst.confirm.open = true;
      inst.confirm.program = name;
      inst.confirm.id = id;
    },
    getTableHeight() {
      const header = document.getElementById('table-height-standard');
      const height = parseInt(getComputedStyle(header).height);
      const except = {};
      this.table_height = height;
      return height;
    },
    getRecentData(){
      this.$http.APS.get('/process/all').then(r=>{
        for(let i = 0; i < r.data.length; i++){
          if(r.data[i].lot_endtime){
            r.data[i].lot_endtime= r.data[i].lot_endtime.substr(0,19).replace('T',' ');
          }
        }

        this.machine_status.data = r.data;
      })
    },
    openProcessForm() {
      const work = this.work_instruct;
      work.dialog.mode = 'create';
      work.dialog.open = true;
    },
    closeCreateProcess() {
      const work = this.work_instruct;
      this.resetDialog();
    },
    async createProcess() {
      var form = this.$refs.form;
      if(form.validate()){
        const work = this.work_instruct;
        await this.$http.APS.post('/order/manage',work.dialog);
        work.dialog.open = false;
        await this.getWork();
      };
    },
    openScheduleForm(v) {
      const status = this.machine_status;
      status.dialog.open = true;
      status.dialog.mkey = v;
    },
    closeAddSchedule() {
      const status = this.machine_status;
      this.resetStatus();
    },
    async addSchedule() {
      if(this.$refs.form1.validate()){
        const status = this.machine_status;
        const dialog = status.dialog;
        var math = (60*dialog.setting_time)+(dialog.active_time * dialog.quantity);
        var start = dialog.date+' '+dialog.time ; 
        var end = moment(start).add(math,'seconds').format('YYYY-MM-DD HH:mm:ss');
        dialog.id = this.select_process.id;
        dialog.start = start;
        dialog.end = end;
        dialog.setting_time_value = 60 * dialog.setting_time;
        await this.$http.APS.patch('/order/estimated',dialog);
        await this.getWork();
        await this.resetStatus();
      }
    },
    resetDialog(){
      const work = this.work_instruct;
      var dialog =  {
          mode:'create',
          open: false,
          buyer_id: null,
          product_name: '',
          quantity: '',
          due_date: moment().format('YYYY-MM-DD'),
        }
      work.dialog = dialog;
    },
    resetStatus(){
      const status = this.machine_status;
      var dialog_form = {
          open: false,
          setting_time: undefined,
          active_time: undefined,
          quantity: undefined,
          date: moment().format('YYYY-MM-DD'),
          time: '12:00',
      }
      status.dialog = dialog_form;
    },
    setDayTime(v){
      const dialog = this.machine_status.dialog;
      dialog.date = v.date;
      dialog.time = v.time;
    },
    setDate(v){
      this.work_instruct.dialog.due_date = v ;
    },
    tableSort(value) {
      const sort = this.work_instruct.sort;
      sort.sort_by = value;
      sort.sort_desc = !sort.sort_desc;
    },
  },
  mounted() {
    this.getTableHeight();
    this.getWork();
    this.getSelect();
    this.getRecentData();
  },
  watch:{
    valid:function(v){
      console.log(v);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/global.scss";

  .create-process {
    height: 100%;
    position: relative;
    padding-right: 60%;

    .block-title {
      @extend .flex_row;

      justify-content: space-between;
      height: 42px;
      padding-left: 60px;
      margin-bottom: 20px;
      font-size: 26px;
      font-weight: 600;

      @include relative-web1 {
        padding-left: 16px;
      }

      .sub-title {
        font-weight: 500 !important;
      }
    }

    .v-data-table {
      @extend .data-table--skyblue-theme;
    }

    .work-instruction {
      @extend .flex_column;

      justify-content: stretch;
      width: 100%;
      height: 100%;
      padding: 60px 35px 60px 0;

      .block-title {
        width: 100%;
        flex: 0 0 auto;

        .create-process {
          width: 150px;
          height: 40px;
          border: 1px solid $skyblue_darken1 !important;
          font-size: 18px !important;
          letter-spacing: -0.5px;

          &.mac {
            padding-top: 3px;
          }
        }
      }
      .work-instruction-table {
        flex: 1 1 0;
        width: 100%;

        .skyblue-data-table {

          tr {

            &:hover {

              .work-instruction__actions {
                display: block;
              }
            }
          }
        }

        ::v-deep .work-instruction__actions {
          display: none;
          height: 48px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          padding: 8px 16px;

          .action-btn {
            width: 32px;
            height: 32px;
            border: 1px solid black;
            margin-right: 10px;
            background-color: rgba(255, 255, 255, 0.8);
            
            &:last-child {
              margin-right: 0;
            }

            .v-icon {
              position: relative;

              .cls-1 {
                fill: black !important;
              }
            }
          }
        }
      }
    }
    .machine-status {
      height: 100%;
      width: 60%;
      padding: 60px 0 60px 55px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      transition: width 0.3s ease-in-out;
      background: white;

      &.machine-status--expand {
        width: 100%;
        transition: width 0.3s ease-in-out;
      }

      .block-title {
        justify-content: flex-start;

        .sub-title {
          margin-left: 8px;
          position: relative;

          &::before {
            content: '';
            display: inline-block;
            width: 3px;
            height: 23px;
            margin: 0 10px;
            position: relative;
            bottom: -1px;
            background-color: black;
          }
        }
      }
      ::v-deep .expand-machine-status {
        width: 20px !important;
        min-width: unset !important;
        height: 100%;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.07);
      }

      .machine-status-table {
        
        .add-process-schedule {
          border: 1px solid $skyblue-darken1 !important;
          font-size: 18px;
          padding-bottom: 2px;
          letter-spacing: -0.5px;

          &.mac {
            padding-bottom: 0;
            padding-top: 3px !important;
          }
        }
      }
    }
  }

  ::v-deep .create-new-process-dialog {
    @extend .dialog--black-theme;
  }
  ::v-deep .process-schedule-dialog {
    @extend .dialog--black-theme;
  }
  ::v-deep .process-delete-confirm {
    @extend .dialog--black-theme;
  }
</style>